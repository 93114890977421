<template>
  <div class="flex flex-col flex-grow">
    <div class="ml-4 py-3 pr-3 border-b border-blue-500 flex items-center">
      <button
        v-if="searchValue"
        @click="back"
        class="focus:outline-none mr-2">
        <img
          src="@/assets/images/chevron_left.svg"
          class="w-4 h-4">
      </button>
      <form
        @submit.prevent="search"
        class="w-full">
        <input
          type="text"
          v-model="searchValue"
          :placeholder="$t('the_teams_overview.placeholder_search_users')"
          class="form-input-blocked"
        >
      </form>
      <router-link
        v-if="event.canCreateTeam"
        :to="{name: 'team-create'}"
        class="flex-none ml-2 h-7 w-7"
      >
        <img
          src="@/assets/images/plus.svg"
          class="h-7 w-7"
        />
      </router-link>
    </div>
    <v-scrollable
      ref="scrollable"
      v-if="!loading && !error">
      <div v-if="!searchValue && users === null">
        <teams-list :teams="teams"/>
        <router-link
          :to="{name: 'invited-users-overview'}"
          class="block focus:outline-none focus:bg-gray-100 hover:bg-gray-100"
        >
          <div
            class="flex flex-row justify-between items-center
              ml-4 mt-12 pr-4 py-3 border-t border-b border-blue-500">
            <p
              v-text="$t('the_teams_overview.label_invited')"
              class="uppercase"></p>
            <div class="flex items-center">
              <p v-text="event.invitedUsersCount"></p>
              <img
                src="@/assets/images/ic_chevron_right_blue.svg"
                class="w-3 h-3 ml-2">
            </div>
          </div>
        </router-link>
      </div>
      <users-list
        v-if="users !== null"
        :users="users"/>
    </v-scrollable>
    <v-loading v-if="loading"/>
    <v-error
      v-if="error"
      v-model="error"/>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import infinityScroll from '@/mixins/infinityScroll';
  import TeamsList from '@/components/lists/TeamsList.vue';
  import UsersList from '@/components/lists/UsersList.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import VError from '@/components/common/VError.vue';
  import VScrollable from '@/components/common/VScrollable.vue';

  export default {
    name: 'TheTeamsOverview',
    components: {
      VLoading,
      VError,
      VScrollable,
      TeamsList,
      UsersList,
    },
    mixins: [infinityScroll],
    data() {
      return {
        loading: true,
        error: null,
        searchValue: null,
      };
    },
    computed: {
      teams() {
        return this.$store.getters['team/list'];
      },
      users() {
        return this.$store.getters['user/list'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    created() {
      this.loadTeams();

      this.$watch('searchValue', debounce(this.search, 500));
    },
    beforeDestroy() {
      this.$store.commit('team/clearList');
      this.$store.commit('user/clearList');
    },
    methods: {
      loadTeams() {
        this.loading = true;

        this.$store.dispatch('team/loadByEventId', this.event.id)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      search() {
        this.error = null;

        this.detachInfiniteScroll();

        this.$store.commit('user/clearList');

        if (!this.searchValue) {
          this.loading = false;

          return;
        }

        this.loading = true;
        this.loadUsers();
      },
      loadUsers() {
        this.$store
          .dispatch('user/searchForEvent', {
            eventId: this.event.id,
            searchValue: this.searchValue,
          })
          .then(() => {
            this.loading = false;

            this.$nextTick(() => {
              this.attachInfiniteScroll(this.$refs.scrollable, () => {
                this.loadUsers();
              });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      back() {
        this.$store.commit('user/clearList');
        this.searchValue = null;
      },
    },
  };
</script>

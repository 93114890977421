var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col flex-grow" },
    [
      _c(
        "div",
        {
          staticClass:
            "ml-4 py-3 pr-3 border-b border-blue-500 flex items-center"
        },
        [
          _vm.searchValue
            ? _c(
                "button",
                {
                  staticClass: "focus:outline-none mr-2",
                  on: { click: _vm.back }
                },
                [
                  _c("img", {
                    staticClass: "w-4 h-4",
                    attrs: { src: require("@/assets/images/chevron_left.svg") }
                  })
                ]
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search($event)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue"
                  }
                ],
                staticClass: "form-input-blocked",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "the_teams_overview.placeholder_search_users"
                  )
                },
                domProps: { value: _vm.searchValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchValue = $event.target.value
                  }
                }
              })
            ]
          ),
          _vm.event.canCreateTeam
            ? _c(
                "router-link",
                {
                  staticClass: "flex-none ml-2 h-7 w-7",
                  attrs: { to: { name: "team-create" } }
                },
                [
                  _c("img", {
                    staticClass: "h-7 w-7",
                    attrs: { src: require("@/assets/images/plus.svg") }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.loading && !_vm.error
        ? _c(
            "v-scrollable",
            { ref: "scrollable" },
            [
              !_vm.searchValue && _vm.users === null
                ? _c(
                    "div",
                    [
                      _c("teams-list", { attrs: { teams: _vm.teams } }),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "block focus:outline-none focus:bg-gray-100 hover:bg-gray-100",
                          attrs: { to: { name: "invited-users-overview" } }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row justify-between items-center\n            ml-4 mt-12 pr-4 py-3 border-t border-b border-blue-500"
                            },
                            [
                              _c("p", {
                                staticClass: "uppercase",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("the_teams_overview.label_invited")
                                  )
                                }
                              }),
                              _c("div", { staticClass: "flex items-center" }, [
                                _c("p", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.event.invitedUsersCount
                                    )
                                  }
                                }),
                                _c("img", {
                                  staticClass: "w-3 h-3 ml-2",
                                  attrs: {
                                    src: require("@/assets/images/ic_chevron_right_blue.svg")
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.users !== null
                ? _c("users-list", { attrs: { users: _vm.users } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.loading ? _c("v-loading") : _vm._e(),
      _vm.error
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
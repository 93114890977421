<template>
  <ul>
    <li
      v-for="team in teams"
      :key="team.id">
      <router-link
        :to="{name: 'team-detail', params: {id: `${team.id}`}}"
        class="block focus:outline-none focus:bg-gray-100 hover:bg-gray-100">
        <div class="uppercase flex justify-between ml-4 py-3 pr-4 border-b border-blue-500">
          <p
            v-text="team.name"
            class="w-3/4 truncate"></p>
          <div class="flex">
            <p v-text="team.usersCount"></p>
            <p class="ml-2 flex items-center">
              <img
                src="@/assets/images/ic_chevron_right_blue.svg"
                class="h-3 w-3"
              >
            </p>
          </div>
        </div>
      </router-link>
    </li>
    <li
      v-if="teams.length === 0"
      v-text="$t('teams_list.label_empty_list')"
      class="ml-4 pr-4 py-3 text-md text-gray-900">
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'TeamsList',
    props: {
      teams: { type: Array, default: () => [] },
    },
  };
</script>
